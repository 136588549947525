import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link } from 'gatsby'
import { Message, List } from 'semantic-ui-react'
// Components
import { Seo, Primary as SubmitButton } from '../components'
import { useAuthContext } from '../context/AuthContext'
// Styles
import {
  Wrapper,
  Container,
  StyledButton,
  StyledForm,
  StyledInput,
  FieldWrapper,
  StyledCheckbox,
  StyledCheckboxLabel,
  StyledDivider,
  StyledLabel,
  QLogo,
  PageHeader,
} from '../styles/RegisterPage.styles'
import logo from '../svg/black-q-sciences.svg'
// Utils & Services
import { accountEnrollmentSchema } from '../utils/validations'
import { StyledSpan } from '../components/Cart/CartProductCard.styles'
import { URLParams, FormData } from '../types/enrollmentTypes'

const PcBenefits = () => (
  <Container direction="column" align="flex-start">
    <div>
      For only $10 a year, Preferred Customers save up to 30% on all Q Sciences
      products, unlock subscription orders, earn Q Rewards to get free products,
      receive exclusive promotions, and more. Start saving today!
    </div>
    <div style={{ marginTop: '20px' }}>Preferred Customer Benefits</div>
    <List bulleted style={{ marginTop: '0' }}>
      <List.Item>Up to 30% off all Q Sciences products</List.Item>
      <List.Item>Subscription orders with added perks</List.Item>
      <List.Item>
        May purchase PC Smart Ship – free shipping on all subscription orders
        for a year
      </List.Item>
      <List.Item>Earn Q Rewards Points for free products</List.Item>
      <List.Item>
        Access to exclusive promotions and limited time offers
      </List.Item>
      <List.Item>Exclusive product bundles and stacks</List.Item>
      <List.Item>Only $10 a year</List.Item>
    </List>
  </Container>
)

const Register = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [isPC, setIsPC] = useState(true)
  const [agreeToTerms, setAgree] = useState(false)
  const [receiveText, setReceiveText] = useState(false)
  const [receiveEmail, setReceiveEmail] = useState(false)
  const {
    firebaseUser,
    isQAccountVerified,
    handleNewUserRegistration,
    apiError,
    setApiError,
  } = useAuthContext()

  // URL param values sent from login
  const { state: urlParams } = location

  function setUrlParamValues({
    firstname,
    lastname,
    telephone,
    referred_by_name,
  }: URLParams): void {
    if (urlParams?.associate_type !== 'pc') {
      setIsPC(false)
    }
    setValue('firstname', firstname)
    setValue('lastname', lastname)
    setValue('telephone', telephone)
    setValue('referred_by_name', referred_by_name)
  }

  // TODO - Need to add a way to resume enrollment process if bail out occurs as USER will already have a Firebase account

  useEffect(() => {
    // redirect to login if no firebase user data
    // if (!firebaseUser) navigate('/login')

    // redirect to home if no referral info to re init QS verification process
    if (!urlParams) return

    setUrlParamValues(urlParams)
  }, [firebaseUser])

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(accountEnrollmentSchema),
  })

  const onSubmit = async (data: FormData): Promise<void> => {
    const payload = {
      ...data,
      receive_email: receiveEmail,
      receive_text: receiveText,
      is_pc: isPC,
    }
    console.log(payload) // XXX

    // NOTE - QS auth headers have already been set
    // setLoading(true)
    // await handleNewUserRegistration(payload)
    // setLoading(false)
    // navigate('/')
  }

  const handleErrors = () => (
    <Message
      error
      onDismiss={() => setApiError(null)}
      header="Sign in error..."
      content={apiError}
    />
  )

  return (
    <>
      <Seo title="Register" />
      <Wrapper>
        <Container>
          <QLogo src={logo} />
        </Container>
        <PageHeader>Choose Your Account</PageHeader>
        <Container justify="space-evenly">
          <StyledButton active={isPC} onClick={() => setIsPC(true)}>
            Preferred Customer
          </StyledButton>
          <StyledButton active={!isPC} onClick={() => setIsPC(false)}>
            Retail
          </StyledButton>
        </Container>
        {isPC ? (
          <PcBenefits />
        ) : (
          <Container direction="column">
            Retail Customers can purchase Q Sciences products at full retail
            pricing and are not able to set up subscription orders.
          </Container>
        )}
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Container justify="start" style={{ margin: '20px 0 0' }}>
            {!!apiError && handleErrors(errors)}
            <span style={{ fontSize: '12px' }}>*Required Fields</span>
          </Container>
          <Container direction="column">
            {urlParams && !errors.referred_by_name && (
              <StyledLabel>Referred By</StyledLabel>
            )}
            {errors.referred_by_name && (
              <StyledLabel isError>
                {errors.referred_by_name?.message}
              </StyledLabel>
            )}
            <StyledInput
              {...register('referred_by_name')}
              placeholder="Who Referred You?"
            />
          </Container>
          <Container direction="column">
            {urlParams && !errors.firstname && (
              <StyledLabel>Your First Name</StyledLabel>
            )}
            {errors.firstname && (
              <StyledLabel isError>{errors.firstname?.message}</StyledLabel>
            )}
            <StyledInput {...register('firstname')} placeholder="First Name*" />
          </Container>
          <Container direction="column">
            {urlParams && !errors.lastname && (
              <StyledLabel>Your Last Name</StyledLabel>
            )}
            {errors.lastname && (
              <StyledLabel isError>{errors.lastname?.message}</StyledLabel>
            )}
            <StyledInput {...register('lastname')} placeholder="Last Name*" />
          </Container>
          <Container direction="column" style={{ marginBottom: '1em' }}>
            {urlParams && !errors.telephone && (
              <StyledLabel>Your Telephone</StyledLabel>
            )}
            {errors.telephone && (
              <StyledLabel isError>{errors.telephone?.message}</StyledLabel>
            )}
            <StyledInput
              {...register('telephone')}
              placeholder="Phone Number*"
              style={{ marginBottom: '5px' }}
              type="tel"
            />
          </Container>
          <Container justify="space-between">
            <FieldWrapper>
              <StyledCheckbox
                label="Sign Me Up For Texts"
                checked={receiveText}
                onChange={() => setReceiveText(!receiveText)}
              />
            </FieldWrapper>

            <FieldWrapper>
              <StyledCheckbox
                label="Sign Me Up For Emails"
                checked={receiveEmail}
                onChange={() => setReceiveEmail(!receiveEmail)}
              />
            </FieldWrapper>
          </Container>
          <Container direction="column">
            <StyledDivider />

            <FieldWrapper style={{ marginTop: '25px' }}>
              <StyledSpan style={{ fontWeight: 'bold', fontSize: '14px' }}>
                {isPC
                  ? 'Preferred Customer Agreement'
                  : 'Retail Customer Agreement'}
              </StyledSpan>
            </FieldWrapper>
            <FieldWrapper align="flex-start" style={{ marginTop: '10px' }}>
              <StyledCheckbox
                id="agreement"
                name="agreement"
                checked={agreeToTerms}
                onChange={() => setAgree(!agreeToTerms)}
              />
              <StyledCheckboxLabel htmlFor="accept_privacy">
                I have read, understand and agree to the Q Sciences Privacy
                Policy and <Link to="/terms">Website Terms of Use</Link>
              </StyledCheckboxLabel>
            </FieldWrapper>
          </Container>
          <Container style={{ marginTop: '20px' }}>
            <SubmitButton
              type="submit"
              disabled={Object.entries(errors).length !== 0 || !agreeToTerms}
              loading={loading}
            >
              {isPC ? 'REGISTER' : 'START SHOPPING'}
            </SubmitButton>
          </Container>
        </StyledForm>
      </Wrapper>
    </>
  )
}

export default Register
