import styled, { css } from 'styled-components'
import { Divider, Checkbox, Image, Header } from 'semantic-ui-react'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 1.5rem;
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'flex-start'};
  width: 100%;
  max-width: 500px;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const StyledInput = styled.input`
  padding: 10px;
  width: ${props => props.width || '100%'};
  max-width: 500px;
  border: 1px solid #222;
  border-radius: 10px;
  margin-bottom: 1rem;
  font-size: 16px;

  ::placeholder {
    color: ${props => props.theme.colors.silver};
  }
  :focus {
    border: 1px solid ${props => props.theme.colors.metallicSeaweed};
    border-radius: 10px;
  }
`

export const StyledCheckboxLabel = styled.label`
  padding-left: 10px;
`

export const FieldWrapper = styled.div`
  display: flex;
  align-items: ${props => props.align || 'center'};
`

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 25px 0 0 !important;
`

export const StyledLabel = styled.span`
  color: ${props => (props.isError ? props.theme.colors.carnelian : props.theme.colors.dimGray)};
  align-self: flex-start;
  margin-bottom: 0;
`

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 5px;
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 10px;

  :hover {
    cursor: pointer;
  }

  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid #007985;
    `}

  ${props =>
    props.details &&
    css`
      opacity: 0.5;
      margin-top: 10px;
      font-weight: normal;
    `}
`

export const StyledCheckbox = styled(Checkbox)`
  align-self: start;
  padding-top: 2px;
`

export const QLogo = styled(Image)`
  &&& {
    width: 80%;
    height: auto;

    @media ${({ theme }) => theme.device.sm} {
      width: 250px;
    }
  }
`

export const PageHeader = styled(Header)`
  &&& {
    margin-top: 0;
    font-size: 14px;
  }
`

export const SignInTitle = styled.span`
  font-size: 2em;
  font-weight: 100;
  margin: 0.5em 0 1em;
`
